import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import AdminLayout from "./AdminLayout";
import BillingStatusAdmin from "./BillingStatusAdmin";
import ApiStatusAdmin from "./ApiStatusAdmin";
import Faq from "./Faq";
import AdminLogin from "../../Components/Authentication/AdminLogin";
import Profile from "./Profile";
import UserDetails from "./UserDetails";
import ApiStatusAdminPublicly from "./ApiStatusAdminPublicly";
import TransactionHistory from "./TransactionHistory";

function App() {
  return (
    <>
      <AdminLayout>
        <Routes>
          <Route path="/" element={<AdminLogin />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/admindashboard" element={<Dashboard />}></Route>
          <Route path="/billingstatistics" element={<BillingStatusAdmin />}></Route>
          <Route path="/apistatus" element={<ApiStatusAdmin />}></Route>
          <Route path="/apistatusbilling" element={<ApiStatusAdminPublicly />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/UserDetails" element={<UserDetails />}></Route>
          <Route path="/transaction" element={<TransactionHistory />} />
        </Routes>
      </AdminLayout>
    </>
  );
}

export default App;
