import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Input, Button, Space, message, Modal } from "antd";
import { CreateFaqApi, DeleteFaqAPI, GetFaqAPI, UpdateFaqApi } from "../../redux/auth/authSlice";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import "../../style/admin.css";

const { Panel } = Collapse;

const Faq = () => {
  const dispatch = useDispatch();
  const { ALLfaq } = useSelector((state) => state.counter);
  const [faqTitle, setFaqTitle] = useState("");
  const [faqDescription, setFaqDescription] = useState("");
  const [editFaq, setEditFaq] = useState({ id: null, faqtitle: "", faqdescription: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState(null);


  useEffect(() => {
    dispatch(GetFaqAPI({ dispatch }));
  }, [dispatch]);

  const HandleTitleChange = (e) => {
    setFaqTitle(e.target.value);
  };

  const HandleDescriptionChange = (e) => {
    setFaqDescription(e.target.value);
  };

  const HandleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const HandleSubmit = () => {
    if (!faqTitle.trim() || !faqDescription.trim()) {
      message.error("Both title and description are required.");
      return;
    }
    const obj = {
      faqtitle: faqTitle,
      faqdescription: faqDescription,
      user_id: localStorage.getItem("userid"),
    };
    dispatch(CreateFaqApi({ obj, dispatch }));
    setFaqTitle("");
    setFaqDescription("");
    setIsModalVisible(false);
    message.success("FAQ added successfully!");
  };

  const HandleEdit = (id, title, description) => {
    setEditFaq({ id, faqtitle: title, faqdescription: description });
  };

  const HandleEditChange = (field, value) => {
    setEditFaq((prev) => ({ ...prev, [field]: value }));
  };

  const HandleEditSubmit = (id) => {
    if (!editFaq.faqtitle.trim() || !editFaq.faqdescription.trim()) {
      message.error("Both title and description are required.");
      return;
    }
    const obj = {
      faqId: id,
      faqtitle: editFaq.faqtitle,
      faqdescription: editFaq.faqdescription,
    };
    dispatch(UpdateFaqApi({ obj, dispatch }));
    setEditFaq({ id: null, faqtitle: "", faqdescription: "" });
    message.success("FAQ updated successfully!");
  };

  const HandleDelete = (id) => {
    const obj = { faqId: id };
    dispatch(DeleteFaqAPI({ dispatch, obj }));

    message.success("FAQ deleted successfully!");
    setIsDeleteModalVisible(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFaqTitle("");
    setFaqDescription("");
  };

  const showDeleteModal = (faq) => {
    setFaqToDelete(faq);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setFaqToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (faqToDelete) {
      HandleDelete(faqToDelete._id);
    }
  };

  const filteredFaqs = ALLfaq?.data?.filter((faq) => faq?.faq_title?.toLowerCase()?.includes(searchQuery));

  return (
    <div className="privacyPolicy_css">
      <h1>Frequently Asked Questions (FAQ)</h1>

      <div style={{ marginBottom: 16 }}>
        <Input placeholder="Search FAQ" value={searchQuery} onChange={HandleSearch} style={{ width: 300, marginRight: 8 }} />
        <Button type="primary" onClick={showModal}>
          Add FAQ
        </Button>
      </div>

      <Collapse accordion>
        {filteredFaqs?.map((ele) => (
          <Panel header={editFaq.id === ele._id ? "Editing FAQ" : ele?.faq_title} key={ele._id}>
            {editFaq.id === ele._id ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Input placeholder="Edit FAQ Title" value={editFaq.faqtitle} onChange={(e) => HandleEditChange("faqtitle", e.target.value)} style={{ width: "100%" }} />
                <Input.TextArea
                  placeholder="Edit FAQ Description"
                  value={editFaq.faqdescription}
                  onChange={(e) => HandleEditChange("faqdescription", e.target.value)}
                  rows={4}
                  style={{ width: "100%" }}
                />
                <Button type="primary" onClick={() => HandleEditSubmit(ele._id)}>
                  Save
                </Button>
              </Space>
            ) : (
              <>
                <p>{ele?.faq_description}</p>
              </>
            )}
            <div style={{ marginTop: 8, display: "flex", gap: "8px" }}>
              <Button type="text" icon={<FaEdit />} onClick={() => HandleEdit(ele._id, ele.faq_title, ele.faq_description)}>
                Edit
              </Button>
              <Button type="text" danger icon={<FaTrashAlt />} onClick={() => showDeleteModal(ele)}>
                Delete
              </Button>
            </div>
          </Panel>
        ))}
      </Collapse>

      <Modal title="Add New FAQ" visible={isModalVisible} onOk={HandleSubmit} onCancel={handleCancel} okText="Add FAQ" cancelText="Cancel">
        <Input placeholder="Enter FAQ Title" value={faqTitle} onChange={HandleTitleChange} style={{ marginBottom: 16 }} />
        <Input.TextArea placeholder="Enter FAQ Description" value={faqDescription} onChange={HandleDescriptionChange} rows={3} style={{ marginBottom: "20px" }} />
      </Modal>

      <Modal title="Delete FAQ" visible={isDeleteModalVisible} onOk={handleConfirmDelete} onCancel={handleDeleteCancel} okText="Delete" cancelText="Cancel">
        <p>Are you sure you want to delete this FAQ?</p>
      </Modal>
    </div>
  );
};

export default Faq;
